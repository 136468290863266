<template>
  <nav
    class="flex"
    aria-label="Breadcrumb"
  >
    <ol class="bg-white rounded-lg shadow px-6 flex space-x-4">
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="flex"
      >
        <div class="flex items-center">
          <svg
            v-if="index !== 0"
            class="flex-shrink-0 w-6 h-full text-gray-200"
            viewBox="0 0 24 44"
            preserveAspectRatio="none"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
          </svg>
          <div
            v-if="breadcrumbs.length === 1"
            class="flex-shrink-0 w-0 h-11 text-gray-200"
          >
          </div>
          <router-link
            v-if="breadcrumb.link && $route.name !== breadcrumb.link"
            :to="{ name: breadcrumb.link, params: breadcrumb.params }"
            class="text-sm font-medium text-gray-500 hover:text-secondary duration-150 ease-out"
          >
            <span :class="[ index !== 0 ? 'ml-4 text-sm' : 'text-sm']">{{ breadcrumb.name }}</span>
          </router-link>
          <span
            v-else-if="($route.name === breadcrumb.link) || !breadcrumb.link"
            :class="['ml-4 text-sm', highlight(breadcrumb.link)]"
          >{{ breadcrumb.name }}</span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: null
    }
  },

  methods: {
    highlight(breadcrumb) {
      if (!breadcrumb) {
        return 'text-gray-500 font-medium'
      }
      if (this.$route.name === breadcrumb) {
        return 'font-medium'
      }
    }
  }
}
</script>
