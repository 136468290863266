<template>
  <FetchCandidate :with-profile="true">
    <template slot-scope="{ candidate, profile, loading }">
      <div class="bg-gray-100 flex-grow">
        <div class="w-full max-w-screen-xl relative mx-auto py-8 px-4 rounded-lg relative">
          <transition
            enter-active-class="transition-all duration-500 ease-out"
            leave-active-class="transition-all duration-150 ease-in"
            enter-class="opacity-0"
            leave-class="opacity-100"
            enter-to-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div
              v-if="loading"
              class="absolute inset-0"
              style="margin-top: 100px;"
            >
              <Loader />
            </div>
            <CandidateWizardDashboard
              v-if="candidate && profile"
              :candidate="candidate"
              :profile="profile"
            />
          </transition>
        </div>
      </div>
    </template>
  </FetchCandidate>
</template>

<script>
import FetchCandidate from '@components/CandidateWizard/FetchCandidate'
import CandidateWizardDashboard from '@components/CandidateWizard/CandidateWizardDashboard'
import Loader from '@components/Loader'

export default {
  components: {
    CandidateWizardDashboard,
    FetchCandidate,
    Loader
  }
}
</script>
