<script>
import candidateWizardApi from '@api/candidateWizard'

export default {

  props: {
    withProfile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      candidate: null,
      profile: null,
      loading: true
    }
  },

  created() {
    this.fetchCandidate()

    if (this.withProfile) {
      this.fetchProfile()
    }
  },

  methods: {
    fetchCandidate() {
      return candidateWizardApi.show()
        .then(response => {
          this.loading = false
          this.candidate = response
        })
        .catch(error => {
          this.loading = false
          throw error
        })
    },

    fetchProfile() {
      return candidateWizardApi.fetchProfile()
        .then(response => {
          this.loading = false
          this.profile = response
        })
        .catch(error => {
          this.loading = false
          throw error
        })
    }
  },

  render() {
    return this.$scopedSlots.default({
      candidate: this.candidate,
      profile: this.profile,
      loading: this.loading
    })
  }
}
</script>
