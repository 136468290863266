<template>
  <li class="border rounded-lg p-4 flex flex-col">
    <div class="md:flex md:items-center md:justify-between">
      <div class="mr-3">
        <h2 class="font-bold mb-4 md:mb-0">
          <span class="text-xl">{{ job.name }}</span> <span class="block text-gray-600 font-medium">at {{ job.organisationName }}</span>
        </h2>
        <p class="text-sm text-gray-600">
          {{ job.invited }}
        </p>
      </div>

      <div v-if="!job.deadlineExpired">
        <BaseButton
          class="flex-shrink-0 w-full sm:w-32 text-center shadow-none"
          variant="ghost"
          :disabled="allAssessmentsForJobComplete"
          :to="{
            name: 'candidate-wizard-job',
            params: {
              job: job.wizardSlug
            }}"
        >
          <template slot="iconLeft">
            <Icon
              view-box="0 0 20 20"
              class="mr-2 w-5 h-5"
            >
              <GridView v-if="!allAssessmentsForJobComplete" />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </Icon>
          </template>
          Tests
        </BaseButton>

        <BaseButton
          class="flex-shrink-0 w-full sm:w-32 text-center shadow-none sm:ml-4 mt-6 sm:mt-0"
          :to="{ name: 'candidate-wizard-profile-edit', query: { job: job.wizardSlug } }"
          variant="ghost"
          :disabled="allProfileFieldsHidden || allProfileFieldsForJobComplete"
        >
          <template slot="iconLeft">
            <Icon
              view-box="0 0 20 20"
              class="mr-2 w-5 h-5"
            >
              <Edit v-if="!allProfileFieldsHidden && !allProfileFieldsForJobComplete" />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </Icon>
          </template>
          Profile
        </BaseButton>

        <BaseButton
          v-if="job.personalQuestions && job.personalQuestions.length"
          class="flex-shrink-0 w-full sm:w-32 text-center shadow-none sm:ml-4 mt-6 sm:mt-0"
          variant="ghost"
          :disabled="personalQuestionsComplete"
          :to="{
            name: 'candidate-wizard-personal-questions',
            params: {
              job: job.wizardSlug
            }}"
        >
          <template slot="iconLeft">
            <Icon
              view-box="0 0 20 20"
              class="mr-2 w-5 h-5"
            >
              <g v-if="!personalQuestionsComplete">
                <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
              </g>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </Icon>
          </template>
          Q&amp;A
        </BaseButton>
      </div>

      <BaseButton
        v-if="job.deadlineExpired"
        variant="gray"
        class="flex-shrink-0 w-40 text-gray-800"
        disabled
      >
        Deadline expired
      </BaseButton>
    </div>
    <p
      v-if="allSectionsComplete"
      class="text-sm text-secondary mt-3"
    >
      Thank you. All tasks for this job are complete.
    </p>
  </li>
</template>

<script>
import Icon from '@components/Icons/Icon'
import GridView from '@components/Icons/GridView'
import Edit from '@components/Icons/Edit'

const flatten = require('flat')
const unflatten = require('flat').unflatten

const ArrayIncludes = (array, target) => target.every(value => array.includes(value))

export default {
  components: {
    Icon,
    GridView,
    Edit
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    job: {
      type: Object,
      default: null
    },
    profile: {
      type: Object,
      default: null
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    personalQuestionsComplete() {
      const personalQuestionsUuids = this.job.personalQuestions.map(question => question.uuid)
      const personalAnswersUuids = this.candidate.personalAnswers.map(answer => answer.personalQuestionUuid)
      return ArrayIncludes(personalAnswersUuids, personalQuestionsUuids)
    },

    /**
     * @return {Array}
     */
    requiredProfileFields() {
      const fields = Object.keys(this.profile).filter(field => !this.job.hiddenProfileFields.includes(field))
      // 'employment' is covered by 'employmentStatus'
      return fields.filter(field => field !== 'employment')
    },

    /**
     * @return {Array}
     */
    completeProfileFieldsForJob() {
      const profileFields = flatten(this.profile)

      // Ignore profile fields that dont have a value
      let completedProfileFields = Object.keys(profileFields)
        .filter((key) => (profileFields[key] !== null && profileFields[key].length !== 0 && profileFields[key] !== 0 && profileFields[key] !== false))
        .reduce((accumulator, key) => ({ ...accumulator, [key]: profileFields[key] }), {})
      return Object.keys(unflatten(completedProfileFields))
    },

    /**
     * @return {Boolean}
     */
    allProfileFieldsForJobComplete() {
      return this.requiredProfileFields.every(field => this.completeProfileFieldsForJob.includes(field))
    },

    /**
     * @return {Boolean}
     */
    allProfileFieldsHidden() {
      return this.job.hiddenProfileFields && this.job.hiddenProfileFields.length === 8
    },

    /**
     * @return {Boolean}
     */
    allAssessmentsForJobComplete() {
      const candidateAttemptsUuids = this.candidate.attempts.map(attempt => attempt.examId)
      const examsUuids = this.job.exams.map(attempt => attempt.uuid)
      const customExamUuids = this.job.customExams.map(exam => exam.uuid)

      const hasCompletedExams = ArrayIncludes(candidateAttemptsUuids, examsUuids)
      const hasCompletedCustomExams = ArrayIncludes(candidateAttemptsUuids, customExamUuids)

      return hasCompletedExams && hasCompletedCustomExams
    },

    /**
     * @return {Boolean}
     */
    allSectionsComplete() {
      return this.allProfileFieldsForJobComplete && this.allAssessmentsForJobComplete && this.personalQuestionsComplete
    }
  }
}
</script>
