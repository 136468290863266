<template>
  <section>
    <Breadcrumbs
      :breadcrumbs="breadcrumbs"
      class="mb-8"
    />
    <div class="grid grid-cols-1 gap-4 items-start lg:grid-cols-3">
      <div class="grid grid-cols-1 gap-4 lg:col-span-3">
        <section aria-labelledby="candidate-dashboard">
          <div class="rounded-lg bg-white overflow-hidden shadow">
            <h1
              id="candidate-dashboard"
              class="sr-only"
            >
              Candidate Dashboard
            </h1>
            <div class="bg-white p-6">
              <div class="sm:flex sm:items-center sm:justify-between">
                <div class="sm:flex items-center sm:space-x-5">
                  <span
                    v-if="!profile.photo"
                    class="inline-block h-20 w-20 rounded-full overflow-hidden bg-gray-100"
                  >
                    <svg
                      class="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                  <div
                    v-if="photoUrl"
                    class="flex-shrink-0"
                  >
                    <img
                      class="mx-auto h-20 w-20 rounded-full"
                      :src="photoUrl"
                      alt="Profile Photo"
                    >
                  </div>
                  <div class="mt-4 text-center sm:mt-0 sm:text-left">
                    <p class="font-medium text-gray-600">
                      Hello,
                    </p>
                    <p class="text-2xl font-bold text-gray-800 leading-8">
                      {{ candidate.firstName }} {{ candidate.surname }}
                    </p>
                  </div>
                </div>
                <BaseButton
                  v-if="!job"
                  class="flex-shrink-0 w-full sm:w-32 text-center shadow-none sm:ml-4 mt-6 sm:mt-0"
                  :to="{ name: 'candidate-wizard-profile-edit' }"
                  variant="ghost"
                >
                  <template slot="iconLeft">
                    <Icon
                      view-box="0 0 20 20"
                      class="mr-2 w-5 h-5"
                    >
                      <Edit />
                    </Icon>
                  </template>
                  Profile
                </BaseButton>
              </div>
            </div>
            <div class="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
              <div class="px-6 py-5 font-medium text-center space-x-1">
                <span
                  class="font-medium text-xl"
                  v-html="jobInvitations"
                ></span>
              </div>

              <div class="px-6 py-5 font-medium text-center space-x-1">
                <span
                  class="font-medium text-xl"
                  v-html="testsCompleted"
                ></span>
              </div>
            </div>
          </div>
        </section>

        <section v-if="candidate.jobs.length">
          <div class="rounded-lg bg-gray-200 shadow">
            <div class="rounded-lg bg-white p-6">
              <h2 class="text-lg font-medium">
                Jobs
              </h2>

              <ul class="mt-6 space-y-6">
                <CandidateWizardJobListing
                  v-for="job in candidate.jobs"
                  :key="job.uuid"
                  :job="job"
                  :candidate="candidate"
                  :profile="profile"
                />
              </ul>
            </div>
          </div>
        </section>
      </div>

      <!-- <div class="grid grid-cols-1">
        <section
          class="lg:col-span-1"
        >
          <div class="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2 class="text-lg font-medium">
              Activity
            </h2>

            <div class="mt-6 flow-root">
              <Activity
                :activity="activity"
                class="-mb-8"
              />
            </div>
          </div>
        </section>
      </div> -->
    </div>
  </section>
</template>

<script>
// import Activity from '@components/CandidateWizard/Activity'
import CandidateWizardJobListing from '@components/CandidateWizard/CandidateWizardJobListing'
import Breadcrumbs from '@components/Breadcrumbs'
import Icon from '@components/Icons/Icon'
import Edit from '@components/Icons/Edit'

import pluralize from 'pluralize'

export default {
  components: {
    // Activity,
    CandidateWizardJobListing,
    Breadcrumbs,
    Icon,
    Edit
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    profile: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      job: null,
      cloudinaryCloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME
    }
  },

  computed: {
    jobInvitations() {
      return `${this.candidate.jobs.length} <span class="font-medium text-gray-600 text-base">Job ${pluralize('invitation', this.candidate.jobs.length)}</span>`
    },

    testsCompleted() {
      return `${this.candidate.attempts.length} <span class="font-medium text-gray-600 text-base">${pluralize('Test', this.candidate.attempts.length)} completed</span>`
    },

    activity() {
      return [...this.candidate.attempts, {
        personalQuestionsAnswered: !!this.candidate.personalAnswers.length,
        createdAt: this.candidate.personalAnswers.length ? this.candidate.personalAnswers[0].createdAt : null
      }]
    },

    photoUrl() {
      if (!this.profile.photo) {
        return
      }
      return `https://res.cloudinary.com/${this.cloudinaryCloudName}/image/upload/w_100,h_100,c_fill/v${this.profile.photo.version}/${this.profile.photo.publicId}.${this.profile.photo.format}`
    },

    breadcrumbs() {
      return [
        {
          name: 'Dashboard',
          link: 'candidate-wizard-dashboard'
        }
      ]
    }
  }
}
</script>
