var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"border rounded-lg p-4 flex flex-col"},[_c('div',{staticClass:"md:flex md:items-center md:justify-between"},[_c('div',{staticClass:"mr-3"},[_c('h2',{staticClass:"font-bold mb-4 md:mb-0"},[_c('span',{staticClass:"text-xl"},[_vm._v(_vm._s(_vm.job.name))]),_vm._v(" "),_c('span',{staticClass:"block text-gray-600 font-medium"},[_vm._v("at "+_vm._s(_vm.job.organisationName))])]),_c('p',{staticClass:"text-sm text-gray-600"},[_vm._v(" "+_vm._s(_vm.job.invited)+" ")])]),(!_vm.job.deadlineExpired)?_c('div',[_c('BaseButton',{staticClass:"flex-shrink-0 w-full sm:w-32 text-center shadow-none",attrs:{"variant":"ghost","disabled":_vm.allAssessmentsForJobComplete,"to":{
          name: 'candidate-wizard-job',
          params: {
            job: _vm.job.wizardSlug
          }}}},[_c('template',{slot:"iconLeft"},[_c('Icon',{staticClass:"mr-2 w-5 h-5",attrs:{"view-box":"0 0 20 20"}},[(!_vm.allAssessmentsForJobComplete)?_c('GridView'):_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z","clip-rule":"evenodd"}})])],1)],1),_vm._v(" Tests ")],2),_c('BaseButton',{staticClass:"flex-shrink-0 w-full sm:w-32 text-center shadow-none sm:ml-4 mt-6 sm:mt-0",attrs:{"to":{ name: 'candidate-wizard-profile-edit', query: { job: _vm.job.wizardSlug } },"variant":"ghost","disabled":_vm.allProfileFieldsHidden || _vm.allProfileFieldsForJobComplete}},[_c('template',{slot:"iconLeft"},[_c('Icon',{staticClass:"mr-2 w-5 h-5",attrs:{"view-box":"0 0 20 20"}},[(!_vm.allProfileFieldsHidden && !_vm.allProfileFieldsForJobComplete)?_c('Edit'):_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z","clip-rule":"evenodd"}})])],1)],1),_vm._v(" Profile ")],2),(_vm.job.personalQuestions && _vm.job.personalQuestions.length)?_c('BaseButton',{staticClass:"flex-shrink-0 w-full sm:w-32 text-center shadow-none sm:ml-4 mt-6 sm:mt-0",attrs:{"variant":"ghost","disabled":_vm.personalQuestionsComplete,"to":{
          name: 'candidate-wizard-personal-questions',
          params: {
            job: _vm.job.wizardSlug
          }}}},[_c('template',{slot:"iconLeft"},[_c('Icon',{staticClass:"mr-2 w-5 h-5",attrs:{"view-box":"0 0 20 20"}},[(!_vm.personalQuestionsComplete)?_c('g',[_c('path',{attrs:{"d":"M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"}}),_c('path',{attrs:{"d":"M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z"}})]):_c('svg',{staticClass:"h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z","clip-rule":"evenodd"}})])])],1),_vm._v(" Q&A ")],2):_vm._e()],1):_vm._e(),(_vm.job.deadlineExpired)?_c('BaseButton',{staticClass:"flex-shrink-0 w-40 text-gray-800",attrs:{"variant":"gray","disabled":""}},[_vm._v(" Deadline expired ")]):_vm._e()],1),(_vm.allSectionsComplete)?_c('p',{staticClass:"text-sm text-secondary mt-3"},[_vm._v(" Thank you. All tasks for this job are complete. ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }